import React, { useState } from "react";
import "./about.css";

import NavBar from "../../components/pageComponents/navBar/navBar";
import Lottie from "react-lottie";
import animationData from "../../components/lotties/astronautStuding.json"



export const About = () => {

  const [lottie, setLottie] = useState({ isStopped: false, isPaused: false });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <main>
      <nav className="navBar">
        <NavBar Home="Home" Contact="Contact" Projects="Projects" />
      </nav>
      <body className="body-about">
        <section className="section-content-about">
          <div className="content-div-about">
            <div className="content-span-about">
              <span className="content-span-about-text">
                <p className="paragraphy" style={{ color: "white" }}>
                Highly experienced Administrative Manager
transitioning to a Software Developer role
with a passion for programming, problemsolving,
and innovation. Proficient in multiple
programming languages, with a proven track
record of quickly mastering new
technologies. Seeking a junior-level software
developer position to apply skills and
knowledge gained through self-study and
formal education.
                </p>
              </span>
              <span className="content-span-about-img">
                <Lottie options={defaultOptions}
                  height={400}
                  width={400}
                  isStopped={lottie.isStopped}
                  isPaused={lottie.isPaused} />
              </span>
            </div>

          </div>
        </section>
      </body>
    </main>


  )
}

export default About;