import React from 'react';
import { useState, useEffect } from 'react';
import "./projects.css";
import NavBar from "../../components/pageComponents/navBar/navBar";
import imagem1 from "../../components/images projects/1.png"
import imagem2 from "../../components/images projects/2.png"
import imagem3 from "../../components/images projects/3.png"
import { Link } from 'react-router-dom';


const projetos = {
  imagens: [imagem1, imagem2, imagem3],
  ids: ['s1', 's2', 's3'],
  links: ["", "", "https://endearing-gnome-6058f8.netlify.app/#"]
};

const Projects = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % projetos.imagens.length);
    }, 2500);

    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <main>
      <nav className="navBar">
      <NavBar Home="Home" Contact="Contact" Projects="Projects" />
      </nav>
      <body className="body-projects">
        <section className="section-content-projects">
          <div className="div-content-projects">
            <div className="slide">
              {projetos.ids.map((id, index) => (
                <input
                  key={id}
                  type="radio"
                  name="slide1"
                  id={id}
                  checked={currentSlide === index}
                  className="inputs"
                />
              ))}
              <div className="imagens">
                {projetos.imagens.map((imagem, index) => (
                  <a href={projetos.links[index]} key={index}>
                    <img
                      src={imagem}
                      width={600}
                      height={600}
                      style={{ display: currentSlide === index ? 'block' : 'none' }}
                    />
                  </a>
                ))}
              </div>
              <div className="labels">
                {projetos.ids.map((id) => (
                  <label key={id} htmlFor={id} id={`label${id}`}></label>
                ))}
              </div>
            </div>
          </div>
        </section>
      </body>
    </main>
  );
};

export default Projects;