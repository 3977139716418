
import React from "react";
import { BrowserRouter as Router, Route, Routes,Navigate, Link, } from "react-router-dom";
import About from "./pages/About/about";
import Home from "./pages/LandPage/LandPage";
import Main from "./pages/LinkPage/main";
import Projects from "./pages/Projects/projects";
import Contact from "./pages/Contact/contact";



function App() {

  return (
   <Router>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/main" element={<Main />} />
        <Route path="/me" element={<About />} />
        <Route path="/projects" element={<Projects/>} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
   </Router> 
    
  );
}

export default App;
