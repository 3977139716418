
import React from "react";
import { useState } from "react";
import Lottie from "react-lottie";
import "./index.css";

import animationData from "../../components/lotties/warmHole.json"
import astronaut from "../../components/lotties/astronaut.json"
import { Link } from "react-router-dom";

function Home() {

  const [lottie, setLottie] = useState({ isStopped: false, isPaused: false })

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const defaultOptionsAstronaut = {
    loop: true,
    autoplay: true,
    animationData: astronaut,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  
  return (
    <section className="sessao" >
      <div className="div">
          <img className="logo" src="/imagens/logo-branco.png" height={150} width={150} />
          <button className="moon">
      
            <Link to="/main"  className="blackHole">
              <Lottie options={defaultOptions}
                height={400}
                width={400}
                isStopped={lottie.isStopped}
                isPaused={lottie.isPaused} />
            </Link>
          </button>
      </div>
    </section>
  )
}

export default Home;