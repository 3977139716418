import React, { useState } from "react";
import "./contact.css";
import NavBar from "../../components/pageComponents/navBar/navBar";


interface FormData {
  name: string;
  email: string;
  text: string;
}

const Contact = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    text: "",
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const response = await fetch("https://formsubmit.co/matheus.joshua@live.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert("Your message was sent successfully!");
    } else {
      alert("There was an error sending your message. Please try again later.");
    }

    // Clear the form
    setFormData({ name: "", email: "", text: "" });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <main>
      <nav className="navBar">
      <NavBar Home="Home" Contact="Contact" Projects="Projects" />
      </nav>
      <body className="body-form">
        <section className="section-content-form">
          <div className="content-div-form">
            <form onSubmit={handleSubmit} id="form">
              <label htmlFor="name">Hi, who are you?</label>
              <input type="text" name="name" className="input-padrao" value={formData.name} onChange={handleInputChange} required />

              <label htmlFor="email">E-mail</label>
              <input type="email" name="email" className="input-padrao" value={formData.email} onChange={handleInputChange} required placeholder="seuemail@dominio.com" />

              <label htmlFor="text">Leave here your thoughts and suggestions..</label>
              <textarea name="text" className="input-padrao" value={formData.text} onChange={handleInputChange} required placeholder="Deixe sua sugestão..."></textarea>

              <button type="submit" className="enviar">Send</button>
            </form>
          </div>
        </section>
      </body>
    </main>

  );
};

export default Contact;