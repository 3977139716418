import React from "react";
import "./main.css";


import { Link } from "react-router-dom";

export const Main = ()=>{


    return(
      <main className="main">
        <body className="body">
          <section className="section-content">
            <div className="content-div">
                <span className="content-links">
                  <ul className="ul-links">
                    <Link className="li-links-Me" to="/me">I`m Matheus!</Link>
                    <Link className="li-links-Projects" to="/projects">Start to learn..</Link>
                    <Link className="li-links-Contact" to="/contact">About me.</Link>
                  </ul>
                  
                </span>
                <span className="content-lottie">
                  <img className="img-logo-branco" src="/imagens/logo-branco.png" alt="Logo" />
                </span>
            </div>
          </section>
        </body>
      </main>
       
        
    )
}

export default Main;