import React from "react";
import "./navBar.css";
import { Link } from "react-router-dom";

interface iNav {
    Home: string,
    Projects: string, 
    Contact: string
}

export const NavBar = ({Home, Projects, Contact}: iNav)=>{
    return(
        <section className="sessao-nav">
            <div className="logo-nav">
                <a href="/"> <img src="/imagens/logo-branco.png" alt="" /> </a>
            </div>
            <div className="div-links">
                <ul className="links">
                    <li><Link className="Link-links" to="/main">{Home}</Link></li>
                    <li><Link className="Link-links" to="/projects">{Projects}</Link></li>
                    <li><Link className="Link-links" to="/contact">{Contact}</Link></li>
                </ul>
            </div>
        </section>
    )
}

export default NavBar